<template>
  <v-scale-screen width="1920" height="1080" class="abcc" :fullScreen="true">
    <div id="monitoringDetail">
      <myHeader></myHeader>
      <div class="detail_top">
        <div class="top_one">
          {{ stationDetails.stationName }}
          <img src="../../assets/images/monitoring/top_icon.png">
        </div>
        <div class="top_two">
          <div class="subCenterImg">
            <img src="../../assets/images/monitoring/two_left.png">
            <div class="subCenter">{{ List1[0].value }}</div>
          </div>
          <div class="two_center">
            <div class="center_div">
              <div class="div_list" style="height: 0.4rem;" v-for="(item, index) in List1" :key="index">
                <img :src="item.imgSrc">
                <span style="color:#AED6FF;">{{ item.name }}</span>
                <el-tooltip v-if="index == 3 && item.value && item.value.length>20" class="item" effect="dark"
                            :content="item.value"
                            placement="top">
                                <span
                                    style="display: -webkit-box; text-align: left; -webkit-box-orient: vertical; max-width: 2rem;  -webkit-line-clamp: 2;  overflow: hidden;text-overflow: ellipsis;">{{
                                    item.value
                                  }}</span>
                </el-tooltip>
                <span v-else>{{ item.value }}</span>
              </div>
            </div>
            <div class="center_div">
              <div class="div_list" style="height: 0.4rem;" v-for="(item, index) in List2" :key="index">
                <img :src="item.imgSrc">
                <span style="color:#AED6FF;">{{ item.name }}</span>
                <el-tooltip v-if="index == 3&& item.value && item.value.length>20" class="item" effect="dark"
                            :content="item.value"
                            placement="top">
                                <span
                                    style="display: -webkit-box; text-align: left; -webkit-box-orient: vertical; max-width: 1.9rem;  -webkit-line-clamp: 1;  overflow: hidden;text-overflow: ellipsis;">{{
                                    item.value
                                  }}</span>
                </el-tooltip>
                <span v-else style="width: 1.9rem; text-align: left;">{{ item.value }}</span>
              </div>
            </div>
            <div class="center_div">
              <div class="div_list" style="height: 0.4rem;" v-for="(item, index) in List3" :key="index">
                <img :src="item.imgSrc">
                <span style="color:#AED6FF;">{{ item.name }}</span>
                <el-tooltip v-if="index == 2&& item.value && item.value.length>20" class="item" effect="dark"
                            :content="item.value"
                            placement="top">
                                <span
                                    style="display: -webkit-box;-webkit-box-orient: vertical;  max-width: 1.8rem; text-align: left;  -webkit-line-clamp: 2;  overflow: hidden;text-overflow: ellipsis;">{{
                                    item.value
                                  }}</span>
                </el-tooltip>
                <span v-else
                      style="display: -webkit-box;-webkit-box-orient: vertical;  max-width: 1.8rem; text-align: left;  -webkit-line-clamp: 2;  overflow: hidden;text-overflow: ellipsis;">{{
                    item.value
                  }}</span>
              </div>
            </div>
          </div>
          <div class="two_right">
            <img style="width:1.3rem;height:1.3rem;" src="../../assets/images/monitoring/center13.png">
            <span style="font-size:0.22rem;color:#2EE1B2; width: 0.5rem;">{{ securityTime.days }}</span>
            <span style="font-size:0.14rem;color:#E8F4FF;position: absolute;
                    top: 1.3rem;
                    left:0.2rem;">安全运维天数</span>
            <span style="font-size:0.12rem;color:#AED6FF;position: absolute;
                    top: 1.5rem;
                    left: -0.1rem;">最后维护时间：{{ securityTime.time }}</span>
          </div>
        </div>
        <div class="top_three">
          <div class="three_div" v-for="(item, index) in List4" :key="index"
               :style="{ backgroundImage: `url(${item.imgUrl})` }">
            <div style="color:#0096FF;font-size:0.16rem;">{{ item.grade }}</div>
            <div style="color:#E8F4FF;font-size:0.12rem;" :class="item.type == 'long' ? 'long' : 'short'">
              {{ item.name }}
            </div>
          </div>
        </div>
        <div class="top_three">
          <div class="three_div" v-for="(item, index) in List5" :key="index"
               :style="{ backgroundImage: `url(${item.imgUrl})` }">
            <div style="color:#0096FF;font-size:0.16rem;">{{ item.grade }}</div>
            <div style="color:#E8F4FF;font-size:0.12rem;">{{ item.name }}</div>
          </div>
        </div>
      </div>
      <div class="detail_center">
        <div class="center_nav" v-for="(item, index) in navList" :key="index"
             :style="{ background: `url(${item.imgUrl}) no-repeat ` }" :class="index == 0 ? '' : 'disabled'">

        </div>
      </div>
      <div class="detail_main">
        <div class="main_left">
          <!-- <div class="left_one">
            <el-select v-model="value" placeholder="请选择">
              <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
              </el-option>
          </el-select>
        </div> -->
          <div class="left_two">
            <div class="two_div" v-for="(item, index) in List6" :key="index"
                 :style="{ backgroundImage: `url(${item.imgUrl}) ` }">
              <div style="color:#0096FF;font-size:0.16rem;">{{ item.grade }}</div>
              <div style="color:#E8F4FF;font-size:0.12rem;">{{ item.name }}</div>
            </div>
          </div>
          <div class="left_two">
            <div class="two_div" v-for="(item, index) in List7" :key="index"
                 :style="{ backgroundImage: `url(${item.imgUrl}) ` }">
              <div style="color:#0096FF;font-size:0.16rem;">{{ item.grade }}</div>
              <div style="color:#E8F4FF;font-size:0.12rem;">{{ item.name }}</div>
            </div>
          </div>
        </div>
        <div class="main_right">
          <!-- 配电室 -->
          <img v-if="stationDetails.type == 1" src="../../assets/images/monitoring/main_right.png">
          <img v-if="stationDetails.type == 2" src="../../assets/images/monitoring/main_right2.png">
          <img v-if="stationDetails.type == 3" src="../../assets/images/monitoring/main_right3.png">
          <img v-if="stationDetails.type == 4" src="../../assets/images/monitoring/main_right4.png">
        </div>
        <div class="main_tj">
          <div class="bottom_dlqk">
            <img src="../../assets/images/monitoring/ydtitle.png" style="margin-right:0.1rem;vertic">
            <span>设备数量统计</span>
          </div>
          <div class="dlqk_div" v-for="(item, index) in dlqkList" :key="index"
               :style="{ backgroundImage: `url(${item.imgUrl}) ` }">
            <span :style="{ color: item.color }">{{ item.value }}</span>
            <span>{{ item.title }}</span>
          </div>
        </div>
      </div>
      <div class="detail_bottom">
        <div id="detailBottom"></div>
        <div class="bottom_dlqk">
          <img src="../../assets/images/monitoring/ydtitle.png" style="margin-right: 0.1rem; vertic">
          <span>用电量情况统计</span>
        </div>
      </div>
      <HomeFooter></HomeFooter>
      <div class="back_nav" @click="jump()">

      </div>
    </div>
  </v-scale-screen>
</template>
<script>
import myHeader from "../../components/homeHeader.vue";
import xDate from '../../utils/ecahrtXdate.js'
import * as echarts from "echarts";
import HomeFooter from "../../components/footer.vue";
import {monitoringLeftApi} from '@/api/monitoringLeft.js'
import {data} from "jquery";

export default {
  components: {myHeader, HomeFooter},
  data() {
    return {
      title: '东城区永定门东街保修材料供应中心',
      List1: [
        {
          imgSrc: require('../../assets/images/monitoring/center1.png'),
          name: '所属分中心：',
          value: ''
        },
        {
          imgSrc: require('../../assets/images/monitoring/center2.png'),
          name: '场站编码：',
          value: ''
        },
        {
          imgSrc: require('../../assets/images/monitoring/center3.png'),
          name: '城区位置：',
          value: ''
        },
        {
          imgSrc: require('../../assets/images/monitoring/center4.png'),
          name: '地址：',
          value: ''
        },
      ],
      List2: [
        {
          imgSrc: require('../../assets/images/monitoring/center5.png'),
          name: '配电形式：',
          value: ''
        },
        {
          imgSrc: require('../../assets/images/monitoring/center6.png'),
          name: '联系人：',
          value: ''
        },
        {
          imgSrc: require('../../assets/images/monitoring/center7.png'),
          name: '联系方式：',
          value: ''
        },
        {
          imgSrc: require('../../assets/images/monitoring/center8.png'),
          name: '主要线路：',
          value: ''
        },
      ],
      List3: [
        {
          imgSrc: require('../../assets/images/monitoring/center9.png'),
          name: '产权单位：',
          value: ''
        },
        {
          imgSrc: require('../../assets/images/monitoring/center10.png'),
          name: '管理单位：',
          value: ''
        },
        {
          imgSrc: require('../../assets/images/monitoring/center11.png'),
          name: '运维单位：',
          value: ''
        },
        {
          imgSrc: require('../../assets/images/monitoring/center12.png'),
          name: '使用单位：',
          value: ''
        },
      ],
      List4: [
        {
          imgUrl: require('../../assets/images/fl.png'),
          grade: '',
          name: '空气质量'
        },
        {
          imgUrl: require('../../assets/images/wd.png'),
          grade: '',
          name: '环境温度（℃）',
          type: 'long'
        },
        {
          imgUrl: require('../../assets/images/snwd.png'),
          grade: '',
          name: '水浸',

        }
      ],
      List5: [
        {
          imgUrl: require('../../assets/images/fx.png'),
          grade: '',
          name: '风向'
        },
        {
          imgUrl: require('../../assets/images/sd.png'),
          grade: '',
          name: '环境湿度RH',
          type: 'long'
        },
        {
          imgUrl: require('../../assets/images/zaoyin.png'),
          grade: '',
          name: '噪音（dB）',
          type: 'long'
        }
      ],
      List6: [
        {
          imgUrl: require('../../assets/images/monitoring/main1.png'),
          grade: '',
          name: '总用电量(GWh)'
        },
        {
          imgUrl: require('../../assets/images/monitoring/main6.png'),
          grade: '',
          name: '等效植树（棵）',
        },
        {
          imgUrl: require('../../assets/images/monitoring/main4.png'),
          grade: '',
          name: '无功功率（kVar）',
        }
      ],
      List7: [
        {
          imgUrl: require('../../assets/images/monitoring/main5.png'),
          grade: '',
          name: 'CO2减排(万吨)'
        },

        {
          imgUrl: require('../../assets/images/monitoring/main7.png'),
          grade: '',
          name: '有功功率（kw）',
        },
        {
          imgUrl: require('../../assets/images/monitoring/main8.png'),
          grade: '',
          name: '电压等级（kv）',
        }
      ],
      navList: [
        {
          imgUrl: require('../../assets/images/monitoring/dian.png'),
        },
        {
          imgUrl: require('../../assets/images/monitoring/shui.png'),
        },
        {
          imgUrl: require('../../assets/images/monitoring/qi.png'),
        },
        {
          imgUrl: require('../../assets/images/monitoring/nuan.png'),
        }
      ],
      xDate: [],
      dlqkList: [
        {
          imgUrl: require('../../assets/images/monitoring/pdtqk.png'),
          title: '配电图数量',
          color: '#28C39E',
          value: ''
        },
        {
          imgUrl: require('../../assets/images/monitoring/sxtqk.png'),
          title: '摄像头数量',
          color: '#FFC74A',
          value: ''
        },
        {
          imgUrl: require('../../assets/images/monitoring/byqsbqk.png'),
          title: '变压器数量',
          color: '#2E8FF6',
          value: ''
        },
        {
          imgUrl: require('../../assets/images/monitoring/gygqk.png'),
          title: '高压柜数量',
          color: '#DA1212',
          value: ''
        },
        {
          imgUrl: require('../../assets/images/monitoring/dygqk.png'),
          title: '低压柜数量',
          color: '#EF7F3E',
          value: ''
        },
        {
          imgUrl: require('../../assets/images/monitoring/zlpqk.png'),
          title: '直流屏数量',
          color: '#AE56F1',
          value: ''
        },
      ],
      stationId: '',
      value: '',
      options: [],
      stationDetails: {},
      currentStation: {},
      securityTime: {
        time: '',
        days: ''
      },
      envStation: {},
      tq: {
        feng: '',
        yu: ''
      },

    }
  },
  mounted() {
    this.stationId = this.$route.query.id
    this.getStationCurrent()
    //   this.xDate = xDate.xDate.xDate2400
    this.setChart1()
    console.log(this.stationId);
    this.getDetailStation()
    this.getStaComList()
    this.getStationEnv()
    this.getEquipmentNum()
    this.setWeather()

  },
  methods: {
    //天气接口
    setWeather() {
      // 创建一个 Date 对象
      var now = new Date();
      // 获取当前年份
      var currentYear = now.getFullYear();
      // 获取当前月份（注意，月份是从0开始计数的，所以需要加1）
      var currentMonth = now.getMonth() + 1;
      // 获取当前日期
      var currentDate = now.getDate();
      //获取当前小时
      var currentHour = now.getHours();
      if (currentMonth < 10) {
        currentMonth = '0' + currentMonth
      }
      if (currentDate < 10) {
        currentDate = '0' + currentDate
      }
      let param = {
        qybms: '110000',
        fPeriod: currentYear + "-" + currentMonth + "-" + currentDate + " " + currentHour + ':00',
        tPeriod: currentYear + "-" + currentMonth + "-" + currentDate + " " + currentHour + ':00',
      }
      monitoringLeftApi.getWeatherHourData(param).then(res => {
        if (res.code == 200) {
          console.log("111111111", this.tq);
          this.tq = res.data[0].sdtqInfos[0]
          this.List5[0].grade = this.tq.fxms
          this.List4[0].grade = this.tq.kqzl
          // console.log("111111",this.tq);

        }
      })
    },
    //跳转到综合监控大屏
    jump() {
      this.$router.push('/monitoringLeft')
    },
    setChart1() {
      let ydlLine = this.$echarts.init(
          document.getElementById("detailBottom")
      );
      monitoringLeftApi.powerStatistics({stationId: this.stationId}).then(res => {

        let listArr = res[0]
        this.xDate = Object.keys(listArr)
        var listValue = Object.values(listArr).map(value => value === null ? 0 : value);
        var option = {
          backgroundColor: '',
          color: ["#2EE1B2"],
          grid: {
            top: '100',
            bottom: '40'
          },
          legend: {
            icon: 'circle',
            data: ['电网供电'],
            top: 40,
            right: 80,
            textStyle: {
              color: "#fff"
            }
          },
          tooltip: {
            trigger: "axis",
          },

          xAxis: [
            {
              type: "category",
              axisLine: {

                lineStyle: {
                  color: "#103562",
                },
              },
              axisTick: {
                show: false,
              },
              data: this.xDate,
              axisLabel: {
                interval: 0,
                color: "#BDCCDF",
              },
            },
          ],
          yAxis: {
            type: "value",
            name: "单位：MWh",
            min: 0,
            minInterval: 1,
            nameTextStyle: {
              color: "#BDCCDF",
              fontSize: 12,
            },
            axisLabel: {
              color: "#BDCCDF",
              textStyle: {
                fontSize: 14,
              },
            },

            axisTick: {
              show: false,
            },
            axisLabel: {
              textStyle: {
                fontSize: 14,
                color: "#BDCCDF",
              },
            },
            splitLine: {
              lineStyle: {
                type: 'dashed',//
                color: "#0b2159"
              },
            },
            splitArea: {
              show: false,
            },
          },
          series: [
            {
              name: "电网供电",
              type: "line",
              symbolSize: 5,
              smooth: false,
              symbol: "none", //在单个图表实例中存在多个y轴的时候有用
              // data: [4000,4300,4600,4900,5100,5500,5900,6100,6500,6900,7300,7860,6100,6500,6900,7300,7860,6100,6500,6900,7300,7860],
              data: listValue,
              lineStyle: {
                width: 2,
                color: 'rgba(13,183,116,0.38)'
              },
              areaStyle: {
                opacity: 0.8,
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: "rgba(13,183,116,0.38)",
                  },
                  {
                    offset: 1,
                    color: "rgb(13,183,116,0)",
                  },
                ]),
              },
            },
          ],
        };
        ydlLine.setOption(option);
        window.addEventListener("resize", function () {
          ydlLine.resize();
        });
      })
    },
    //获取当前场站
    getStationCurrent() {
      monitoringLeftApi.getStationCurrent({stationId: this.stationId}).then(res => {
        this.currentStation = res.data
        console.log(this.currentStation.levelOfVoltage);
        if (this.List6) {
          if (this.List6[0]) {//总用电量
            this.$set(this.List6[0], 'grade', this.currentStation.totalElectricityConsumption);
            // this.List6[0].grade= this.currentStation.TotalElectricityConsumption
          }

          if (this.List6[1] && this.currentStation.equivalentTreePlanting) {//等效植树
            this.$set(this.List6[1], 'grade', parseFloat(this.currentStation.equivalentTreePlanting.toFixed(3)));
          }
          if (this.List6[2]) {//无功功率
            this.$set(this.List6[2], 'grade', this.currentStation.reactivePower);
          }
        }
        if (this.List7 && this.currentStation.co2) {
          if (this.List7[0]) {//CO2
            this.$set(this.List7[0], 'grade', parseFloat(this.currentStation.co2.toFixed(3)));
          }
          if (this.List7[1] && this.currentStation.activePower) {//有功功率
            this.$set(this.List7[1], 'grade', parseFloat(this.currentStation.activePower.toFixed(3)));
          }
          if (this.List7[2]) {//电压等级
            this.$set(this.List7[2], 'grade', res.data.levelOfVoltage);
          }
        }

      })
    },
    //获取场站详情
    getDetailStation() {
      monitoringLeftApi.getDetailStation(this.stationId).then(res => {
        this.stationDetails = res.data
        this.List1[0].value = res.data.companyInfoIdDesc
        this.List1[1].value = res.data.stationNumber
        this.List1[2].value = res.data.areaName
        this.List1[3].value = res.data.address
        this.List2[0].value = res.data.typeDict
        this.List2[2].value = res.data.phone
        this.List2[3].value = res.data.route
        this.List3[0].value = res.data.propertyName
        this.List3[1].value = res.data.companyInfoIdDesc
        this.List3[3].value = res.data.useName
        this.securityTime.days = res.data.operationDays
        this.securityTime.time = res.data.lastMaintenanceTime


      })
    },

    // 根据场站Id获取运维单位
    getStaComList() {
      monitoringLeftApi.getStaComList({stationId: this.stationId}).then(res => {
        this.List3[2].value = res.data.map(item => item.companyName).join(',')
      })
    },
    //获取场站的温湿水噪
    getStationEnv() {
      monitoringLeftApi.getStationEnv({id: this.stationId}).then(res => {
        this.envStation = res
        this.List4[1].grade = res.wendu
        this.List4[2].grade = res.shuijin
        this.List5[1].grade = res.shidu
        this.List5[2].grade = res.zaoshengfenbei
      })
    },
    //获取设备数量
    getEquipmentNum() {
      // monitoringLeftApi.getEquipmentNum({id:this.stationId}).then(res=>{
      monitoringLeftApi.statisticsNumber({stationId: this.stationId}).then(res => {
        // res.forEach(item=>{
        // if(res.data.device_name=='配电图'){
        this.dlqkList[0].value = res.data.distributionChart + '个'
        // }
        // if(item.device_name=='摄像头'){
        this.dlqkList[1].value = res.data.shexiangtou ? res.data.shexiangtou + '个' : 0 + '个'
        // }
        // if(item.device_name=='变压器'){
        this.dlqkList[2].value = res.data.bianyaqi + '个'
        // }
        // if(item.device_name=='高压柜'){
        this.dlqkList[3].value = res.data.gaoyace + '个'
        // }
        // if(item.device_name=='低压柜'){
        this.dlqkList[4].value = res.data.diyace + '个'
        // }
        // if(item.device_name=='直流屏'){
        this.dlqkList[5].value = res.data.zhiliuping + '个'
        // }
        // })
      })
    }
  },

}
</script>

<style scoped>
.back_nav {
  width: 76px;
  height: 30px;
  background: url('../../assets/images/monitoring/back_nav.png') no-repeat;
  position: fixed;
  z-index: 99999;
  right: left;
  top: 1.2rem;
  cursor: pointer;
}

::v-deep .el-input--suffix .el-input__inner {
  background: transparent !important;
  height: 0.5rem;
  width: 3.9rem;
  border: none;
}

.scrollable {
  overflow: auto;
}

#monitoringDetail {
  /* background: url(../../assets/images/panel_bj.jpg) no-repeat; */
  background-color: #071024;
  font-size: 0.24rem;
  overflow: hidden;
}

.detail_top {
  padding-left: 0.32rem;
  padding-right: 0.32rem;
  text-align: center;
  height: 3.08rem;
  width: 100%;
}

.detail_top img {
  margin-left: 0.032rem;
  vertical-align: middle;
}

.top_one {
  margin-top: 0.30rem;
  margin-bottom: 0.30rem;
  color: #E8F4FF;
  background-size: cover;
}

.top_two {
  float: left;
  width: 14.55rem;
  height: 2rem;
  background: url(../../assets/images/top_bg.png) no-repeat;
}

.top_two img {
  width: 2.4rem;
  height: 1.48rem;
  float: left;
  margin: 0.3rem;
}

.two_center {
  width: 9rem;
  height: 2rem;
  float: left;
}

.center_div {
  width: 33%;
  height: 2rem;
  float: left;
  font-size: 0.14rem;
  margin-top: 0.26rem;
}

.center_div img {
  width: 0.18rem;
  height: 0.18rem;
  margin: 0 0.15rem 0.22rem 0;
}

.center_div span {
  float: left;
}

.two_right {
  position: relative;
  width: 1.8rem;
  height: 1.3rem;
  float: left;
  margin: 0 0.2rem 0.2rem 0.5rem
}

.two_right img {
  margin: 0;
}

.two_right span {
  position: absolute;
  top: 0.5rem;
  left: 0.4rem;
}

.top_three {
  float: left;
  width: 1.89rem;
  margin-right: 0.15rem;
}

.three_div {
  width: 1.89rem;
  height: 0.58rem;
  margin-bottom: 0.14rem;
  padding-top: 0.1rem;
  box-sizing: border-box;
}

.three_div div {
  margin-bottom: 0.05rem;
}

.long {
  padding-left: 0.2rem;
}

.detail_center {
  width: 18.59rem;
  height: 0.74rem;
  background: url(../../assets/images/monitoring/center14.png) no-repeat;
  margin-left: 0.32rem;
  margin-right: 0.32rem;
  margin-bottom: 0.1rem;
  cursor: pointer;
}

.center_nav {
  width: 25%;
  height: 0.74rem;
  float: left;
}

.detail_main {
  /* width: 14.37rem; */
  width: 18.59rem;
  height: 4.06rem;
  background: url(../../assets/images/monitoring/center15.png) no-repeat;
  background-size: auto 100%;
  margin-left: 0.32rem;
  margin-right: 0.32rem;
  padding: 0.3rem 0rem 0.3rem 0.3rem;
  box-sizing: border-box;
  margin-bottom: 0.1rem;
}

.main_left {
  float: left;
}

.main_right {
  width: 8.42rem;
  height: 3.47rem;
  float: left;
}

.left_one {
  width: 5.14rem;
  height: 0.5rem;
  padding-left: 1.24rem;
  box-sizing: border-box;
  margin-bottom: 0.1rem;
  background: url(../../assets/images/monitoring/shaixuan.png) no-repeat;
}

.left_two {
  width: 2.49rem;
  float: left;
  margin-right: 0.15rem;
}

.two_div {
  box-sizing: border-box;
  height: 1.05rem;
  width: 2.49rem;
  margin-bottom: 0.15rem;
  padding-left: 0.8rem;
  padding-top: 0.3rem;
}

.main_tj {
  width: 3.94rem;
  height: 8.11rem;
  background: url(../../assets/images/monitoring/sbqkbg.png) no-repeat;
  float: left;
  margin-left: 0.6rem;
  margin-top: -0.3rem;
  padding-top: 0.85rem;
  box-sizing: border-box;
  position: relative;
}

.detail_bottom {
  width: 14.45rem;
  height: 3.92rem;
  background: url(../../assets/images/monitoring/ydlqk.png) no-repeat;
  margin-left: 0.32rem;
  position: relative;
}

#detailBottom {
  width: 14.45rem;
  height: 3.92rem;
}

.bottom_dlqk {
  position: absolute;
  top: 0.32rem;
  left: 0.31rem;
  font-size: 0.18rem;
  color: #E8F4FF;

}

.dlqk_div {
  width: 3.52rem;
  height: 0.95rem;
  margin-left: 0.24rem;
  position: relative;
  margin-bottom: 0.28rem;
}

.dlqk_div span:first-child {
  font-size: 20px;
  position: absolute;
  right: 0.94rem;
  top: 0.16rem;
}

.dlqk_div span:last-child {
  font-size: 12px;
  color: #BCCAF3;
  position: absolute;
  right: 0.84rem;
  top: 0.58rem;
}

.subCenterImg {
  position: relative;

}

.subCenter {
  font-size: 20px;
  color: #992719;
  font-weight: 900;
  font-family: "Microsoft YaHei", sans-serif;
  position: absolute;
  top: 100px;
  width: 240px;
  text-align: center;
  margin: 30px;
}

.disabled {
  cursor: not-allowed;
}
</style>
<style>
.abcc .screen-wrapper {
  overflow-y: scroll !important;
}
</style>